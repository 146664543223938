<template lang="pug">
div.pb-24
	template(v-if="isVisibleData")
		ul.mb-24.flex.items-center.widgets(v-if="res && res.summary")
			li.widget
				.flex.flex-col.card
					p.mb-24.card-text Cреднее занятие в топ-2 страницы в категории
					span.card-value {{res.summary.average_menu_kpi || 0}}%
			li.widget
				.flex.flex-col.card
					p.mb-24.card-text Количество наших SKU в категории
					span.card-value {{res.summary.average_product_count_first_page || 0}}
			li.widget
				.flex.flex-col.card
					p.mb-24.card-text Средняя позиция SKU в категории на двух страницах
					span.card-value {{res.summary.average_rank_first_page || 0}}
			li.widget
				.flex.flex-col.card
					p.mb-24.card-text Среднее кол-во SKU в топ категории
					span.card-value {{res.summary.average_product_count || 0}}
		
		ContainerForData.mb-24(
			width="100%"
			:isLoading="isLoading"
		)
			template(#header-left)
				span.container-title Динамика среднего занятия топа категорий, %
			template(#data)
				.flex.items-center.justify-center.chart-container
					LineChart.chart(:chartData="lineChartData" :options="lineChartOptions")

		ContainerForData(
			width="100%"
			:isLoading="isLoading"
		)
			template(#header-left)
				span.container-title Динамика кол-ва SKU в топ-2 страниц относительно среднего кол-ва SKU
			template(#data)
				.flex.items-center.justify-center.chart-container
					BarChart.chart(:chartData="barChartData" :options="barChartOptions")

	.empty-filter(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
import ContainerForData from "@/components/Nestle/ContainerForData";
import BarChart from "@/components/Chart/BarChart";
import LineChart from "@/components/Chart/LineChart";

export default {
	name: "overview-and-trend",
	components: {
		ContainerForData,
		BarChart,
		LineChart,
	},
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		isVisibleData: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},

	},
	data() {
		return {
			list: [],
			res: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: true,
		}
	},
	computed:{
		hasHistoricalList () {
			return this.res && this.res.historical && this.res.historical.list;
		},
		chartsLabels () {
			if (!this.hasHistoricalList) return [];
			return this.res.historical.list.map((item) => item.date);
		},

		lineChartOptions () {
			return {
				elements: {
					point: {
						radius: 6,
						hoverRadius: 6,
						pointStyle: 'circle',
						backgroundColor: '#FFFFFF',
					}
				},
				plugins: {
					tooltip: {
						callbacks: {
							label: (context) => `${context.formattedValue}%`,
							labelColor: () => {
								return {
									borderColor: '#FFFFFF',
									backgroundColor: '#67AC5B',
									borderWidth: 1,
								};
							},
						},
					},
				},
				scales: {
					y: {
						min: 0,
						max: 100,
					}
				}
			};
		},
		lineChartDatasets () {
			if (!this.hasHistoricalList) return [];
			return [{
				label: '',
				data: this.res.historical.list.map((item) => item['average_menu_kpi']),
				borderColor: '#67AC5B',
			}]
		},
		lineChartData() {
			return {
				labels: this.chartsLabels,
				datasets: this.lineChartDatasets,
			}
		},

		barChartOptions() {
			return {
				interaction: {
					mode: 'index'
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						boxHeight: 17,
						labels: {
							font: {
								size: 14,
								lineHeight: 1,
							}
						}
					},
					tooltip: {
						callbacks: {
							label: (context) => {
								const label = context.dataset.label;
								const value = context.formattedValue;
								return ` ${label}: ${value} шт.`;
							},
						},
					},
				}
			}
		},
		barChartDatasets() {
			if (!this.hasHistoricalList) return [];
			return [
				{
					label: 'Кол-во SKU на двух страницах',
					data: this.res.historical.list.map((item) => item['average_product_count_first_page']),
					backgroundColor: '#67AC5B',
				},
				{
					label: 'Среднее кол-во  SKU',
					data: this.res.historical.list.map((item) => item['average_product_count']),
					backgroundColor: '#9E9E9E',
				},
			];
		},
		barChartData() {
			return {
				labels: this.chartsLabels,
				datasets: this.barChartDatasets,
			};
		},
	},
	methods: {
		async fetch() {
			this.$emit('showData')

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
			};
			try {
				this.isLoading = true
				this.res = await this.$api.nestle.getTaxonomyPlacement(params)
			} catch (e) {
				this.res = null;
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		needUpdateResult: {
			immediate: true,
			async handler() {
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.widgets {
	gap: 24px;
}

.widget {
	flex: 1 1 302px;
}

.card {
	padding: 24px;
	padding-left: 32px;
	padding-bottom: 32px;
	background-color: #fff;
	border: 1px solid #E0E0E0;
	border-radius: 4px;
	text-align: right;
}

.card-text {
	font-family: 'OpenSans';
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	color: #333333;
}

.card-value {
	font-family: 'OpenSans';
	font-size: 24px;
	font-weight: 400;
	line-height: 16px;
	color: #000000;
}

.container-title {
	font-family: 'OpenSans';
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: #757575;
}

.chart-container {
	width: 100%;
}

.chart {
	width: 100%;
}

.empty-filter {
	font-size: 16px;
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
</style>	
