<template lang="pug">
.bar-wrapper(:class="theme" :style="{ width: barWidth }")
    .progress(:style="{ width: progressWidth }")
</template>

<script>
export default {
    props: {
        progressPercent: {
            type: Number,
            required: true,
        },
        width: {
            type: [String, Number],
            default: 98,
        },
        // готовые темы: success, transparent
        theme: {
            type: String,
            default: 'success',
        }
    },

    computed: {
        barWidth() {
            if (this.width === +this.width) {
                return `${this.width}px`;
            }

            return this.width;
        },
        progressWidth() {
            let curentPercent;

            if (this.progressPercent <= 1) {
                curentPercent = 1;
            } else if (this.progressPercent > 100) {
                curentPercent = 100;
            } else {
                curentPercent = this.progressPercent;
            }

            return `${curentPercent}%`
        }
    }
}
</script>

<style scoped lang="scss">
.bar-wrapper {
    height: 24px;

    &.success {
        background-color: #F2F2F2;

        .progress {
            background-color: #67AC5B;
        }
    }

    &.transparent {
        background-color: transparent;

        .progress {
            background-color: #9E9E9E;
        }
    }
}

.progress {
    height: 100%;
}
</style>
